.app {
  /* text-align: center; */
  min-height: 100vh;
  color: #545058;
}

.app-logo {
  height: 15vmin;
  pointer-events: none;
}
